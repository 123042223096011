import React from 'react';
import { Alert as UIAlert } from '@web-passion/uikit';

export default function Alert({
  children,
  error,
  success,
  info,
  style,
  ...other
}) {
  const getAlertType = () => {
    if (error) {
      return `error`;
    }
    if (info) {
      return `info`;
    }
    if (success) {
      return `success`;
    }
    return `success`;
  };
  const alertProps = {
    type: getAlertType(),
    style,
    ...other,
  };
  return <UIAlert {...alertProps}>{children}</UIAlert>;
}
