import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import queryString from 'query-string';
import { useModal, ModalWrapper } from '@web-passion/uikit';

import { BorderRadius } from '../../utils/variables';

import loadComponent from '../Loadable';
import loadLibrary from '../Loadable/library';

const Img = loadLibrary('gatsby-image');

const H3 = loadComponent('h3');
const Form = loadComponent('form');
const Button = loadComponent('button-input');

const saveFields = [
  {
    title: '',
    fields: [
      {
        type: 'input',
        inputType: 'email',
        width: '100%',
        name: 'email',
        label: 'Email Address',
        required: true,
      },
      {
        type: 'submit',
        width: '100%',
        buttonLabel: 'Save for later',
      },
    ],
  },
];

export default function ProductSave({ product, query }) {
  const { openModal, closeModal } = useModal();

  const data = useStaticQuery(graphql`
    query ModalLogo {
      wp {
        ...SiteName
        ...SiteLogo
      }
    }
  `);

  useEffect(() => {
    const options = queryString.parse(query);
    if (!options.saved) {
      openModal();
    }
  }, []);

  const handleSubmit = async ({ data, error, success }) => {
    const submissionUrl = `${process.env.GATSBY_FUNCTIONS_URL}contact/save`;

    const user = await fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .catch(() =>
        error(
          `Unable to save application. Please disable any adblockers or VPN's to proceed.`,
        ),
      );

    const body = JSON.stringify({
      email: data.email,
      url: window.location.href,
      name: product.name,
      user,
    });

    fetch(submissionUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    })
      .then(response => response.json())
      .then(response => {
        if (response.contact) {
          success(
            `Thanks for for choosing visa applications. You will receive an email shortly with a link to return to this application later.`,
          );
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          error({
            message:
              'An unexpected error has occurred. Please try again shortly.',
          });
        }
      })
      .catch(err => error(err));
  };

  const siteName = data.wp.generalSettings.title;
  const siteLogo = data.wp.options.websiteOptions.general.logo;

  return (
    <ModalWrapper>
      <div
        style={{
          backgroundColor: `#fff`,
          borderRadius: BorderRadius,
          width: `680px`,
          maxWidth: `100%`,
          display: `block`,
          margin: `0 auto`,
          padding: `45px`,
        }}
      >
        <div style={backgroundLogo}>
          <Img
            image={siteLogo?.localFile?.childImageSharp?.gatsbyImageData}
            alt={siteLogo?.altText || siteName}
            style={logoImage}
          />

          <div style={modalHeader}>
            <H3 line={1.3} margin="0px">
              Please read carefully
            </H3>
          </div>
          <div style={modalContent}>
            <p>
              Completing your application can take around 10-15 mins. If the
              wrong information is given it can result in a refusal of entry to
              the country so please read all questions carefully.
            </p>
            <p>
              You will need passport information and a credit or debit card to
              make payment
              {/*  (PayPal is also available for those with an account) */}
            </p>
            <p>
              If it is not convenient now we can email a link to the form to be
              completed later on, if not you can click continue to get started.
            </p>
          </div>
        </div>
        <div style={modalForm}>
          <Form name={product.name} fields={saveFields} action={handleSubmit} />
        </div>
        <Button
          onClick={() => closeModal()}
          value="Continue"
          className="form-btn"
          green
        />
      </div>
    </ModalWrapper>
  );
}

const sharedStyle = {
  paddingBottom: `30px`,
  marginBottom: `30px`,
  borderBottom: `1px dotted rgba(0,0,0,0.2)`,
  position: `relative`,
  zIndex: 5,
};

const modalHeader = {
  ...sharedStyle,
};

const modalContent = {
  ...sharedStyle,
};

const modalForm = {};

const backgroundLogo = {
  position: `relative`,
};

const logoImage = {
  position: `absolute`,
  right: 0,
  zIndex: 1,
  opacity: 0.1,
};
