import React, { useEffect, useRef, useState /* , useEffect */ } from 'react';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { ModalProvider } from '@web-passion/uikit';

import FormProvider from '../Form/context';
import StoreData from '../../data/store';

import Row from '../Container/row';
import Col from '../Container/column';
import ProductSave from './save';
import Alert from '../Alert';
import Link from '../Links';

import { Bold, MainColour } from '../../utils/variables';

import './style.css';

import loadComponent from '../Loadable';

const ApplicationForm = loadComponent('product-form');
const H2 = loadComponent('h2');

export default function Product({ product, query, removeListener }) {
  const scrollRef = useRef();

  const [stage, setStage] = useState(0);
  const [additional, setAdditional] = useState(false);
  const [order, setOrder] = useState(false);

  useEffect(() => {
    const queries = queryString.parse(query);
    if (queries.order) {
      setOrder(queries.order);
    }
  }, [query]);

  const form = product.options.form.formBuilder.formGroup;

  const prevStage = e => {
    e.preventDefault();
    if (stage !== 0) {
      setStage(stage - 1);
      scrollRef?.current?.scrollIntoView();
    }
  };

  const nextStage = () => {
    if (form.length - 1 > stage) {
      setStage(stage + 1);
      scrollRef?.current?.scrollIntoView();
    }
  };

  const formProps = {
    name: product.name,
    terms: product.options.termsConditions,
    customTerms:
      product?.termsConditions?.customTcs &&
      product?.termsConditions?.customTcs,
    form,
    stage,
    prevStage,
    query,
    setAdditional,
  };

  const handleSubmit = async ({ data, error }) => {
    if (form.length - 1 > stage) {
      // console.log(`---- Next Stage ----`, stage + 1);
      nextStage();
    } else {
      // console.log(`---- Start Processing ----`);
      const address = {
        first_name: data.first_name,
        last_name: data.last_name,
        street_address: data.street_address,
        city: data.city,
        state: data.county,
        postcode: data.post_code,
        country: data.country,
        email: data.email_address,
        phone: data.phone_number,
      };

      // console.log(`address`, address);

      const user = await fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .catch(() =>
          error(
            `Unable to process application. Please disable any adblockers or VPN's to proceed with your application.`,
          ),
        );

      // console.log(`user`, user);

      const orderData = {
        address,
        product: {
          databaseId: product.databaseId,
          quantity: 1,
        },
        currency: product.options.currency,
        form,
        application: data,
        user,
      };

      const handleResponse = res => {
        // console.log(`res`, res);
        if (res.error) {
          error(res);
        } else {
          const currentSlug =
            typeof window !== 'undefined' && window.location.pathname;

          const nextUrl = additional
            ? `${currentSlug}?order=${res.id}&saved=true`
            : `/checkout/?order=${res.id}`;

          if (additional) {
            // reset();
            removeListener();
            window.location.href = nextUrl;
          } else {
            navigate(nextUrl, {
              state: {
                order: res,
                appointment:
                  // eslint-disable-next-line no-underscore-dangle
                  data?.do_you_have_any_preferred_dates_times_you_wish_to_attend_an_appointment_,
              },
            });
          }
        }
      };

      if (!order) {
        StoreData.createOrder(orderData)
          .then(handleResponse)
          .catch(err => error(err));
      } else {
        StoreData.addToOrder(order, orderData)
          .then(handleResponse)
          .catch(err => error(err));
      }
    }
  };

  const stageTitle = form[stage].title;
  const counterSize = `140px`;

  const stageWidth = 100 / form.length;
  const currentProgress = stageWidth * (stage + 1);

  const saveProps = {
    product,
    query,
  };

  return (
    <>
      {order && (
        <Alert success style={{ marginBottom: `60px` }}>
          You are now adding additional applicants to case reference{' '}
          <strong>#{order}</strong> - If you do not wish to add any more
          applicants{' '}
          <Link
            to={`/checkout/?order=${order}`}
            style={{ color: `rgb(21, 87, 36)`, fontWeight: Bold }}
          >
            click here to proceed to payment
          </Link>
        </Alert>
      )}
      <Row justify="space-between">
        <Col xl={{ width: `25%` }} style={{ textAlign: `center` }}>
          <div
            ref={node => {
              scrollRef.current = node;
            }}
          />
          <div
            style={{
              height: counterSize,
              width: counterSize,
              borderRadius: counterSize,
              backgroundColor: MainColour,
              display: `inline-flex`,
              alignItems: `center`,
              justifyContent: `center`,
              marginBottom: `30px`,
            }}
          >
            <strong style={{ color: `#fff`, fontSize: `60px`, lineHeight: 1 }}>
              {stage + 1}
            </strong>
          </div>
          <H2>{stageTitle}</H2>
          <div
            style={{
              display: `inline-block`,
              width: `250px`,
              height: `10px`,
              borderRadius: `10px`,
              backgroundColor: `#ccc`,
            }}
          >
            <div
              style={{
                width: `${currentProgress}%`,
                height: `10px`,
                borderRadius: `10px`,
                backgroundColor: MainColour,
                transition: `all 0.3s linear`,
              }}
            />
          </div>
          <p style={{ fontSize: `14px`, marginTop: `10px` }}>
            {Number(currentProgress).toFixed(0)}% Complete
          </p>
        </Col>
        <Col className="applicationForm" xl={{ width: `70%` }}>
          <FormProvider action={handleSubmit} /* debug */>
            <ApplicationForm {...formProps} />
          </FormProvider>
        </Col>
      </Row>
      <ModalProvider>
        <ProductSave {...saveProps} />
      </ModalProvider>
    </>
  );
}
